
export const ICONS = {
  tree: {
    viewbox: '0 0 384 512',
    svgString: '<path d="M378.3 378.5L298.4 288h30.6c9 0 17-5 20.8-13.1 3.8-8 2.6-17.3-3.3-24.1L268.4 160h28.9c9.1 0 17.3-5.4 20.9-13.6 3.5-8.1 1.9-17.6-4.2-24.1L203.7 4.8c-6-6.5-17.3-6.5-23.3 0L70.1 122.3c-6.1 6.5-7.8 16-4.2 24.1C69.4 154.7 77.6 160 86.7 160h28.9l-78.1 90.9c-5.8 6.8-7.1 16-3.3 24C38 283 45.9 288 55 288h30.6L5.7 378.5c-6 6.8-7.4 16.1-3.6 24.3 3.8 8.1 12 13.3 21 13.3H160v24.5l-30.3 48.4c-5.3 10.6 2.4 23.2 14.3 23.2h96c11.9 0 19.6-12.5 14.3-23.2L224 440.5V416h136.9c9 0 17.3-5.2 21-13.3 3.8-8.2 2.4-17.5-3.6-24.3z"/>'
  },
  landmark: {
    viewbox: '0 0 512 512',
    svgString: '<path d="M501.6 92.1L267.2 2a32 32 0 0 0 -22.5 0L10.4 92.1A16 16 0 0 0 0 107.1V144c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-36.9c0-6.7-4.1-12.6-10.4-15zM64 192v160H48c-8.8 0-16 7.2-16 16v48h448v-48c0-8.8-7.2-16-16-16h-16V192h-64v160h-96V192h-64v160h-96V192H64zm432 256H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"/>',
  },
  mountain: {
    viewbox: '0 0 640 512',
    svgString: '<path d="M634.9 462.7l-288-448C341 5.5 330.9 0 320 0s-21 5.5-26.9 14.7l-288 448a32 32 0 0 0 -1.2 32.6A32 32 0 0 0 32 512h576c11.7 0 22.5-6.4 28.1-16.7a32 32 0 0 0 -1.2-32.6zM320 91.2L405.4 224H320l-64 64-38.1-38.1L320 91.2z"/>',
  },
  awPyramids: {
    viewbox: '0 0 72 72',
		svgString: `<image href="/aw.pyramids.visited.svg" height="72" width="72" />`,
  },
  awColossus: {
    viewbox: '0 0 72 72',
		svgString: `<image href="/aw.colossus.visited.svg" height="72" width="72" />`,
  },
  awGardens: {
    viewbox: '0 0 72 72',
		svgString: `<image href="/aw.gardens.visited.svg" height="72" width="72" />`,
		altSvgString: `<image href="/aw.gardens.unvisited.svg" height="72" width="72" />`,
  },
  awLighthouse: {
    viewbox: '0 0 72 72',
		svgString: `<image href="/aw.lighthouse.visited.svg" height="72" width="72" />`,
  },
  awMausoleum: {
    viewbox: '0 0 72 72',
		svgString: `<image href="/aw.mausoleum.visited.svg" height="72" width="72" />`,
  },
  awZeus: {
    viewbox: '0 0 72 72',
		svgString: `<image href="/aw.zeus.visited.svg" height="72" width="72" />`,
  },
  awArtemis: {
    viewbox: '0 0 72 72',
		svgString: `<image href="/aw.artemis.visited.svg" height="72" width="72" />`,
  },
  mwChichen: {
    viewbox: '0 0 100 100',
		svgString: `<image href="/mw.chichen.visited.svg" height="100" width="100" />`,
  },
  mwChrist: {
    viewbox: '0 0 100 100',
		svgString: `<image href="/mw.christ.visited.svg" height="100" width="100" />`,
  },
  mwColosseum: {
    viewbox: '0 0 100 100',
		svgString: `<image href="/mw.colosseum.visited.svg" height="100" width="100" />`,
  },
  mwMachuPicchu: {
    viewbox: '0 0 100 100',
		svgString: `<image href="/mw.machu-picchu.visited.svg" height="100" width="100" />`,
  },
  mwGreatWall: {
    viewbox: '0 0 100 100',
		svgString: `<image href="/mw.great-wall.visited.svg" height="100" width="100" />`,
  },
  mwPetra: {
    viewbox: '0 0 100 100',
		svgString: `<image href="/mw.petra.visited.svg" height="100" width="100" />`,
		altSvgString: `<image href="/mw.petra.unvisited.svg" height="100" width="100" />`,
  },
  mwTajMahal: {
    viewbox: '0 0 100 100',
		svgString: `<image href="/mw.taj-mahal.visited.svg" height="100" width="100" />`,
  },
};

const variantTemplate = (iconId, iconVariant, variantSpecificIcon = false) => `<symbol id="${iconId}-${iconVariant}" viewBox="0 0 1000 1000">
    <circle class="datamaps-marker-${iconVariant}-border" stroke-width="50" r="450" cx="500" cy="500"/>
    <use class="datamaps-marker-${iconVariant}-icon" href="#${iconId}-${variantSpecificIcon ? iconVariant + '-' : ''}icon" x="175" y="175" height="650" width="650"/>
  </symbol>`;

const template = (iconId, iconViewbox, iconSvg) => `<svg xmlns="http://www.w3.org/2000/svg">
  <g id="${iconId}-group">
    <symbol id="${iconId}-icon" viewbox="${iconViewbox}">${iconSvg}</symbol>
    ${variantTemplate(iconId, 'visited')}
    ${variantTemplate(iconId, 'unvisited')}
  </g>
</svg>`;

const imageTemplate = (iconId, iconViewbox, iconSvg, altIconSvg) => `<svg xmlns="http://www.w3.org/2000/svg">
  <g id="${iconId}-group">
    <symbol id="${iconId}-visited-icon" viewbox="${iconViewbox}">${iconSvg}</symbol>
    <symbol id="${iconId}-unvisited-icon" viewbox="${iconViewbox}">${altIconSvg}</symbol>
    ${variantTemplate(iconId, 'visited', true)}
    ${variantTemplate(iconId, 'unvisited', true)}
  </g>
</svg>`;
 
const createSvgElement = (svgString) => {
  if (typeof window === `undefined`) {
    return null;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'text/html');
  return  doc.querySelector('g');
};

Object.keys(ICONS).forEach(iconId => {
  const icon = ICONS[iconId];
  if (icon.altSvgString) {
    icon.svgElement = createSvgElement(imageTemplate(iconId, icon.viewbox, icon.svgString, icon.altSvgString));
    return;
  }
  icon.svgElement = createSvgElement(template(iconId, icon.viewbox, icon.svgString));
});
 
