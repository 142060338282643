import React, { useRef, useState } from 'react';
import Section from './section';
import Airplane from '../../content/assets/plane.svg';
import { Parallax } from 'react-scroll-parallax';
import DataMapsWithMarker, {
  createMarkerProps,
  createProjection,
  createDataMapsData,
} from './markerDataMap';

const Travel = () => {

  const [goal, setGoal] = useState(goals[2]);

  const dataMapsContainerRef = useRef(null);
  const dataMapsContainerWidth = dataMapsContainerRef.current?.clientWidth || 1344;

  let dataMapsProps = {data: countries};
  let dataMapsStyle = {marginTop: '-8%'};
  let attribution = null;
  if (goal.type === 'usa') {
    dataMapsProps = {data: states, scope: 'usa'};
    dataMapsStyle = {marginTop: '-5%', marginBottom: '-3%'};
  } else if (goal.type === 'ancient_wonders') {
    dataMapsProps = createMarkerProps(ANCIENT_WONDERS, createProjection([37.5, 36], scaleForSvg(3000)), 'landmark', scaleForSvg(70), ANCIENT_WONDERS_MARKER_OPTIONS);
    attribution = (
      <>
        Icons by <a href="https://openmoji.org/library/#author=Niklas%20Kuntz">Niklas Kuntz</a>
      </>
    );
  } else if (goal.type === 'modern_wonders') {
    dataMapsProps = createMarkerProps(MODERN_WONDERS, createProjection([20, 25], scaleForSvg(300)), 'landmark', scaleForSvg(70));
    attribution = (
      <>
        Icons by imam from <a href="https://thenounproject.com/browse/icons/term/tree">Noun Project</a>
      </>
    );
  } else if (goal.type === 'np') {
    dataMapsProps = createMarkerProps(NATIONAL_PARKS, createProjection([-90, 35], scaleForSvg(425)), 'tree', scaleForSvg(20));
  } else if (goal.type === 'summits') {
    dataMapsProps = createMarkerProps(SEVEN_SUMMITS, null, 'mountain', scaleForSvg(50));
  }

  
  return (
    <Section 
      anchor='adventures'
      title='Adventures'
      subtitle='I like to go places'
      index={2}
      >

      <div className="adventure-stats columns is-mobile is-multiline">
        {goals.map(goal => (
          <a 
            key={goal.title} 
            href="#" 
            className="column has-text-centered is-3-mobile is-2-tablet"
            onClick={(e) => {
              e.preventDefault();
              setGoal(goal);
            }}
          >
            <div>
              <p className="heading has-text-dark">
                {goal.title}
                
              </p>
              <p className="is-size-2">
                <span className="goal-count">{goal.count}</span>
                {goal.completed && (
                  <span className="icon has-text-white-bis">
                    <i className="fas fa-trophy"></i>
                  </span>
                )}
              </p>
            </div>
          </a> 
        ))}
      </div>

      <div 
        style={{
          position: 'relative',
          overflow: 'hidden'
        }}
        >
        <Parallax x={[-10, 110]} y={[0, 600]} tagOuter="figure" styleOuter={{
          zIndex: '10',
              position: 'absolute',
              top: '5%',
              left: '5%',
              bottom: '5%',
              right: '5%',
        }}>
          <img 
            className='filter-accent' 
            src={Airplane} 
            style={{
              width: '10%',
              transform: 'rotate(10deg)',
            }} 
          />
        </Parallax> 
        <div 
          style={{zIndex: 0, ...dataMapsStyle}}
          ref={dataMapsContainerRef}
          >
          <DataMapsWithMarker
            responsive={true}
            geographyConfig={{
              hideAntarctica: false,
              popupOnHover: false,
              highlightOnHover: false,
            }}
            fills={{
              defaultFill: "#F2F2F2",
              visitedFill: '#363636', //"#65C9FF"
            }}
            {...dataMapsProps}
          />
        </div>
        <span className='datamaps-attribution is-size-7'>
          {attribution}
        </span>
      </div>

    </Section>
  );

  function scaleForSvg(scale) {
    if (dataMapsContainerWidth < 1344) {
      return scale * dataMapsContainerWidth / 1344;
    }
    
    return scale;
  }
 
}

export default Travel; 
              
const goals = [
  {title: 'Planets', count: 1, type: 'world'},
  {title: 'Continents', count: 7, type: 'world', completed: true},
  {title: 'Countries*', count: 62, type: 'world'},
  {title: 'United Nations', count: 55, type: 'world'},
  {title: 'Travelers’ Century Club', count: 65, type: 'world'},
  {title: 'States', count: 35, type: 'usa'},
  {title: 'World Heritage Sites', count: 95, type: 'world'},
  {title: 'National Parks', count: 40, type: 'np'},
  {title: 'Ancient Wonders', count: 6, type: 'ancient_wonders'},
  {title: 'Modern Wonders', count: 6, type: 'modern_wonders'},
  {title: 'Seven Summits', count: 1, type: 'summits'},
  {title: 'Seven Seas', count: 7, type: 'world', completed: true},
];
 


const countries = createDataMapsData([
  'ARG',
  'ATA',
  'AUS',
  'AUT',
  'BEL',
  'BGR',
  'BLZ',
  'BRA',
  'BTN',
  'CAN',
  'CHE',
  'CHL',
  'CHN',
  'COL',
  'CRI',
  'CUB',
  'CZE',
  'DEU',
  'DNK',
  'ECU',
  'EGY',
  'ESP',
  'FRA',
  'GBR',
  'GRC',
  'GRL',
  'GTM',
  'GUM',
  'HKG',
  'HUN',
  'IND',
  'ISL',
  'ITA',
  'KHM',
  'LAO',
  'LKA',
  'MEX',
  'MYS',
  'NIC',
  'NLD',
  'NPL',
  'NZL',
  'PAN',
  'PER',
  'PRY',
  'QAT',
  'RSM',
  'RWA',
  'SGP',
  'SLV',
  'SVK',
  'SVN',
  'SWE',
  'THA',
  'TUR',
  'TZA',
  'TWN',
  'UGA',
  'USA',
  'VAT',
  'VNM',
  'ZAF',
]);

const states = createDataMapsData([
  //'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  //'CT',
  //'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  //'IA',
  //'KS',
  //'KY',
  'LA',
  //'ME',
  'MD',
  'MA',
  'MI',
  //'MN',
  //'MS',
  'MO',
  'MT',
  //'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  //'OK',
  'OR',
  'PA',
  //'RI',
  //'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  //'VT',
  'VA',
  'WA',
  //'WV',
  'WI',
  'WY',
]);

const ANCIENT_WONDERS_MARKER_OPTIONS = {
  xOffset: (latLng, markerData, options) => {
    let offset = 0; 
    if (markerData.name.match(/mausoleum/i)) {
      offset = -20;
    }
    return latLng[0] - (options.icon.width / 2) + offset;
  },
  yOffset: (latLng, markerData, options) => {
    let offset = 0; 
    if (markerData.name.match(/artemis/i)) {
      offset = -20;
    }
    return latLng[1] - (options.icon.height / 2) + offset;
  }, 
};
 
const ANCIENT_WONDERS = [
  {
    "name": "Great Pyramid of Giza",
    "coordinates": {
      "latitude": 29.9792,
      "longitude": 31.1342
    },
		visited: true,
    icon: 'awPyramids',
  },
  {
    "name": "Hanging Gardens of Babylon",
    "coordinates": {
      "latitude": 32.5364,
      "longitude": 44.4202
    },
		visited: false,
    icon: 'awGardens',
  },
  {
    "name": "Temple of Artemis at Ephesus",
    "coordinates": {
      "latitude": 37.9497,
      "longitude": 27.3639
    },
		visited: true,
    icon: 'awArtemis',
  },
  {
    "name": "Statue of Zeus at Olympia",
    "coordinates": {
      "latitude": 37.637,
      "longitude": 21.63
    },
		visited: true,
    icon: 'awZeus',
  },
  {
    "name": "Mausoleum at Halicarnassus",
    "coordinates": {
      "latitude": 37.0388,
      "longitude": 27.4241
    },
		visited: true,
    icon: 'awMausoleum',
  },
  {
    "name": "Colossus of Rhodes",
    "coordinates": {
      "latitude": 36.451,
      "longitude": 28.2278
    },
		visited: true,
    icon: 'awColossus',
  },
  {
    "name": "Lighthouse of Alexandria",
    "coordinates": {
      "latitude": 31.214,
      "longitude": 29.8852
    },
		visited: true,
    icon: 'awLighthouse',
  }
];

const MODERN_WONDERS = [
  {
    "name": "Great Wall of China",
    "coordinates": {
      "latitude": 40.4319,
      "longitude": 116.5704
    },
		visited: true,
    icon: 'mwGreatWall',
  },
  {
    "name": "Petra",
    "coordinates": {
      "latitude": 30.3285,
      "longitude": 35.4444
    },
		visited: false,
    icon: 'mwPetra',
  },
  {
    "name": "Christ the Redeemer",
    "coordinates": {
      "latitude": -22.9519,
      "longitude": -43.2105
    },
		visited: true,
    icon: 'mwChrist',
  },
  {
    "name": "Machu Picchu",
    "coordinates": {
      "latitude": -13.1631,
      "longitude": -72.545
    },
		visited: true,
    icon: 'mwMachuPicchu',
  },
  {
    "name": "Chichen Itza",
    "coordinates": {
      "latitude": 20.6843,
      "longitude": -88.5678
    },
		visited: true,
    icon: 'mwChichen',
  },
  {
    "name": "Roman Colosseum",
    "coordinates": {
      "latitude": 41.8902,
      "longitude": 12.4922
    },
		visited: true,
    icon: 'mwColosseum',
  },
  {
    "name": "Taj Mahal",
    "coordinates": {
      "latitude": 27.1751,
      "longitude": 78.0421
    },
		visited: true,
    icon: 'mwTajMahal',
  }
]; 

const NATIONAL_PARKS = [
	{
		"name": "Acadia National Park",
			"coordinates": {
				"latitude": 44.3386,
				"longitude": -68.2733
			}
	},
	{
		"name": "Arches National Park",
		"coordinates": {
			"latitude": 38.7331,
			"longitude": -109.5925
		},
		visited: true,
	},
	{
		"name": "Badlands National Park",
		"coordinates": {
			"latitude": 43.8554,
			"longitude": -102.3397
		},
		visited: true,
	},
	{
		"name": "Big Bend National Park",
		"coordinates": {
			"latitude": 29.2498,
			"longitude": -103.2502
		}
	},
	{
		"name": "Biscayne National Park",
		"coordinates": {
			"latitude": 25.4824,
			"longitude": -80.2083
		},
		visited: true,
	},
	{
		"name": "Black Canyon of the Gunnison National Park",
		"coordinates": {
			"latitude": 38.5754,
			"longitude": -107.7418
		},
		visited: true,
	},
	{
		"name": "Bryce Canyon National Park",
		"coordinates": {
			"latitude": 37.593,
			"longitude": -112.1871
		},
		visited: true,
	},
	{
		"name": "Canyonlands National Park",
		"coordinates": {
			"latitude": 38.3269,
			"longitude": -109.8783
		},
		visited: true,
	},
	{
		"name": "Capitol Reef National Park",
		"coordinates": {
			"latitude": 38.366,
			"longitude": -111.2615
		},
		visited: true,
	},
	{
		"name": "Carlsbad Caverns National Park",
		"coordinates": {
			"latitude": 32.1479,
			"longitude": -104.5567
		},
		visited: true,
	},
	{
		"name": "Channel Islands National Park",
		"coordinates": {
			"latitude": 34.0069,
			"longitude": -119.7785
		}
	},
	{
		"name": "Congaree National Park",
		"coordinates": {
			"latitude": 33.7941,
			"longitude": -80.7821
		}
	},
	{
		"name": "Crater Lake National Park",
		"coordinates": {
			"latitude": 42.8684,
			"longitude": -122.1685
		},
		visited: true,
	},
	{
		"name": "Cuyahoga Valley National Park",
		"coordinates": {
			"latitude": 41.2808,
			"longitude": -81.5678
		}
	},
	{
		"name": "Death Valley National Park",
		"coordinates": {
			"latitude": 36.5054,
			"longitude": -117.0794
		},
		visited: true,
	},
	{
		"name": "Denali National Park",
		"coordinates": {
			"latitude": 63.1148,
			"longitude": -151.1926
		}
	},
	{
		"name": "Dry Tortugas National Park",
		"coordinates": {
			"latitude": 24.6285,
			"longitude": -82.8732
		},
		visited: true,
	},
	{
		"name": "Everglades National Park",
		"coordinates": {
			"latitude": 25.2866,
			"longitude": -80.8987
		},
		visited: true,
	},
	{
		"name": "Gates of the Arctic National Park",
		"coordinates": {
			"latitude": 67.8818,
			"longitude": -153.1861
		}
	},
	{
		"name": "Glacier Bay National Park",
		"coordinates": {
			"latitude": 58.6658,
			"longitude": -136.9002
		}
	},
	{
		"name": "Glacier National Park",
		"coordinates": {
			"latitude": 48.7596,
			"longitude": -113.787
		},
		visited: true,
	},
	{
		"name": "Grand Canyon National Park",
		"coordinates": {
			"latitude": 36.1069,
			"longitude": -112.1129
		},
		visited: true,
	},
	{
		"name": "Grand Teton National Park",
		"coordinates": {
			"latitude": 43.7904,
			"longitude": -110.6818
		},
		visited: true,
	},
	{
		"name": "Great Basin National Park",
		"coordinates": {
			"latitude": 38.9833,
			"longitude": -114.3034
		},
		visited: true,
	},
	{
		"name": "Great Sand Dunes National Park",
		"coordinates": {
			"latitude": 37.7433,
			"longitude": -105.508
		},
		visited: true,
	},
	{
		"name": "Great Smoky Mountains National Park",
		"coordinates": {
			"latitude": 35.6532,
			"longitude": -83.507
		}
	},
	{
		"name": "Guadalupe Mountains National Park",
		"coordinates": {
			"latitude": 31.9237,
			"longitude": -104.8854
		},
		visited: true,
	},
	{
		"name": "Haleakal\u0101 National Park",
		"coordinates": {
			"latitude": 20.7097,
			"longitude": -156.2531
		},
		visited: true,
	},
	{
		"name": "Hawai'i Volcanoes National Park",
		"coordinates": {
			"latitude": 19.4194,
			"longitude": -155.2885
		},
		visited: true,
	},
	{
		"name": "Hot Springs National Park",
		"coordinates": {
			"latitude": 34.5215,
			"longitude": -93.0424
		}
	},
	{
		"name": "Indiana Dunes National Park",
		"coordinates": {
			"latitude": 41.6533,
			"longitude": -87.0524
		},
		visited: true,
	},
	{
		"name": "Isle Royale National Park",
		"coordinates": {
			"latitude": 47.9956,
			"longitude": -88.9094
		}
	},
	{
		"name": "Joshua Tree National Park",
		"coordinates": {
			"latitude": 33.8734,
			"longitude": -115.901
		},
		visited: true,
	},
	{
		"name": "Katmai National Park",
		"coordinates": {
			"latitude": 58.6111,
			"longitude": -155.0631
		}
	},
	{
		"name": "Kenai Fjords National Park",
		"coordinates": {
			"latitude": 59.9174,
			"longitude": -149.6513
		}
	},
	{
		"name": "Kings Canyon National Park",
		"coordinates": {
			"latitude": 36.8879,
			"longitude": -118.5551
		},
		visited: true,
	},
	{
		"name": "Kobuk Valley National Park",
		"coordinates": {
			"latitude": 67.3356,
			"longitude": -159.1372
		}
	},
	{
		"name": "Lake Clark National Park",
		"coordinates": {
			"latitude": 60.4128,
			"longitude": -153.393
		}
	},
	{
		"name": "Lassen Volcanic National Park",
		"coordinates": {
			"latitude": 40.4977,
			"longitude": -121.4207
		},
		visited: true,
	},
	{
		"name": "Mammoth Cave National Park",
		"coordinates": {
			"latitude": 37.1869,
			"longitude": -86.1005
		}
	},
	{
		"name": "Mesa Verde National Park",
		"coordinates": {
			"latitude": 37.2309,
			"longitude": -108.4618
		},
		visited: true,
	},
	{
		"name": "Mount Rainier National Park",
		"coordinates": {
			"latitude": 46.8523,
			"longitude": -121.7603
		},
		visited: true,
	},
	{
		"name": "New River Gorge National Park",
		"coordinates": {
			"latitude": 37.8765,
			"longitude": -81.0402
		}
	},
	{
		"name": "North Cascades National Park",
		"coordinates": {
			"latitude": 48.7718,
			"longitude": -121.2985
		},
		visited: true,
	},
	{
		"name": "Olympic National Park",
		"coordinates": {
			"latitude": 47.8021,
			"longitude": -123.6044
		},
		visited: true,
	},
	{
		"name": "Petrified Forest National Park",
		"coordinates": {
			"latitude": 35.0657,
			"longitude": -109.7874
		},
		visited: true,
	},
	{
		"name": "Pinnacles National Park",
		"coordinates": {
			"latitude": 36.4906,
			"longitude": -121.1825
		},
		visited: true,
	},
	{
		"name": "Redwood National Park",
		"coordinates": {
			"latitude": 41.2132,
			"longitude": -124.0046
		},
		visited: true,
	},
	{
		"name": "Rocky Mountain National Park",
		"coordinates": {
			"latitude": 40.3428,
			"longitude": -105.6836
		},
		visited: true,
	},
	{
		"name": "Saguaro National Park",
		"coordinates": {
			"latitude": 32.2967,
			"longitude": -111.166
		},
		visited: true,
	},
	{
		"name": "Sequoia National Park",
		"coordinates": {
			"latitude": 36.4864,
			"longitude": -118.5658
		},
		visited: true,
	},
	{
		"name": "Shenandoah National Park",
		"coordinates": {
			"latitude": 38.2928,
			"longitude": -78.6796
		}
	},
	{
		"name": "Theodore Roosevelt National Park",
		"coordinates": {
			"latitude": 46.979,
			"longitude": -103.5387
		},
		visited: true,
	},
	{
		"name": "Virgin Islands National Park",
		"coordinates": {
			"latitude": 18.3428,
			"longitude": -64.7486
		}
	},
	{
		"name": "Voyageurs National Park",
		"coordinates": {
			"latitude": 48.4841,
			"longitude": -92.838
		}
	},
	{
		"name": "White Sands National Park",
		"coordinates": {
			"latitude": 32.7792,
			"longitude": -106.1719
		},
		visited: true,
	},
	{
		"name": "Wind Cave National Park",
		"coordinates": {
			"latitude": 43.6045,
			"longitude": -103.4212
		},
		visited: true,
	},
	{
		"name": "Wrangell-St. Elias National Park",
		"coordinates": {
			"latitude": 61.7186,
			"longitude": -142.9856
		}
	},
	{
		"name": "Yellowstone National Park",
		"coordinates": {
			"latitude": 44.428,
			"longitude": -110.5885
		},
		visited: true,
	},
	{
		"name": "Yosemite National Park",
		"coordinates": {
			"latitude": 37.8651,
			"longitude": -119.5383
		}
	},
	{
		"name": "Zion National Park",
		"coordinates": {
			"latitude": 37.2982,
			"longitude": -113.0263
		},
		visited: true,
	},
	{
		"name": "National Park of American Samoa",
		"coordinates": {
			"latitude": -14.2570,
			"longitude": -170.6834, 
		},
		visited: false,
  },
];

const SEVEN_SUMMITS = [
  {
    "name": "Mount Everest",
    "coordinates": {
      "latitude": 27.9881,
      "longitude": 86.9250
    }
  },
  {
    "name": "Aconcagua",
    "coordinates": {
      "latitude": -32.6532,
      "longitude": -70.0109
    }
  },
  {
    "name": "Denali",
    "coordinates": {
      "latitude": 63.0695,
      "longitude": -151.0064
    }
  },
  {
    "name": "Mount Kilimanjaro",
    "coordinates": {
      "latitude": -3.0674,
      "longitude": 37.3556
    },
    visited: true,
  },
  {
    "name": "Mount Elbrus",
    "coordinates": {
      "latitude": 43.3499,
      "longitude": 42.4453
    }
  },
  {
    "name": "Vinson Massif",
    "coordinates": {
      "latitude": -78.5250,
      "longitude": -85.6167
    }
  },
  {
    "name": "Mount Kosciuszko",
    "coordinates": {
      "latitude": -36.4558,
      "longitude": 148.2639
    }
  }
];
