import React, { useState } from 'react';
import d3 from 'd3';
import {ICONS} from '../utils/travelIcons';

let MarkerDataMap = function () { return <div/>; };
if (typeof window !== 'undefined') {
  const DataMaps = require('react-datamaps').default;
  require('../vendor/datamaps/datamaps.markers.js');
 
  class DataMapsWithMarker extends DataMaps {

    drawMap() {
      super.drawMap();

      if (!this.map?.markers) {
        this.map.addPlugin('markers', require('datamaps').customMarkers);
      }

      const {markers, markerOptions} = this.props;
      if (markers) {
        this.map.markers(markers, markerOptions);
      }
    }
  }

  MarkerDataMap = DataMapsWithMarker;
}

export default MarkerDataMap;

export const createMarkerProps = (markers, setProjection, icon, iconSize = 40, options = {}) => {
  return {
    data: {},
    markerOptions: {
      fillOpacity: 1,
      popupOnHover: false,
      icon: {
        url: '/' + icon,
        width: iconSize,
        height: iconSize,
      }, 
      symbols: [
        ICONS[icon].svgElement,
        ...(markers.map((m) => ICONS[m.icon]?.svgElement).filter((i) => !!i))
      ],
      ...options,
    },
    markers: markers.map((m) => {
      return {
        name: m.name, 
        ...m.coordinates, 
        iconHref: `#${m.icon || icon}-${m.visited ? 'visited' : 'unvisited'}`
        //iconHref: `#${icon}-${m.visited ? 'visited' : 'unvisited'}`
      };
    }),
    setProjection,
  };
};

export const createDataMapsData = items => items.reduce((acc, p) => {
  acc[p] = {fillKey: 'visitedFill'};
  return acc;
}, {});

export const createProjection = (center, scale) => {
  return (element) => {
    var projection = d3.geo.equirectangular()
      .center(center)
      .rotate([4.4, 0])
      .scale(scale)
      .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
    var path = d3.geo.path()
      .projection(projection);

    return {path: path, projection: projection}; 
  };
};
 
